import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  private orientationSubject = new BehaviorSubject<string>(this.getDeviceOrientation());
  public deviceOrientation$: Observable<string> = this.orientationSubject.asObservable();

  private sizeSubject = new BehaviorSubject<{ width: number, height: number }>({
    width: window.innerWidth,
    height: window.innerHeight
  });
  public screenSize$: Observable<{ width: number, height: number }> = this.sizeSubject.asObservable();

  constructor() {
    this.updateScreenSizeAndOrientation();
    screen.orientation.addEventListener('change', this.updateScreenSizeAndOrientation.bind(this));
    window.addEventListener('resize', this.updateScreenSizeAndOrientation.bind(this));
    window.addEventListener('orientationchange', this.updateScreenSizeAndOrientation.bind(this));
  }

  private updateScreenSizeAndOrientation = (): void => {
    this.sizeSubject.next({ width: window.innerWidth, height: window.innerHeight });
    this.orientationSubject.next(this.getDeviceOrientation());
  }

  getDeviceOrientation(): string {
    return screen.orientation.type.startsWith('landscape') ? 'landscape' : 'portrait';
  }


  getDeviceType(): string {
    const ua = navigator.userAgent;
    const isTablet = /tablet|ipad|playbook|silk/i.test(ua);
    const isMobile = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(HPw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(ua);

    const isIPadPro = ua.includes("Macintosh") && "ontouchend" in document;

    if (isTablet || isIPadPro) {
      return 'Tablet';
    } else if (isMobile) {
      return 'Mobile';
    }
    return 'Desktop';
  }

  getBrowserType(): string {
    const ua = window.navigator.userAgent;

    if (ua.includes("CriOS")) {
      return "Chrome on iOS";
    } else if (ua.includes("FxiOS")) {
      return "Firefox on iOS";
    } else if (ua.includes("OPR") || ua.includes("Opera")) {
      return "Opera";
    } else if (ua.includes("Edg")) {
      return "Edge";
    } else if (ua.includes("Chrome") && !ua.includes("CriOS")) {
      return "Chrome";
    } else if (ua.includes("Safari") && !ua.includes("Chrome") && !ua.includes("CriOS") && !ua.includes("FxiOS")) {
      return "Safari";
    } else if (ua.includes("Firefox") && !ua.includes("FxiOS")) {
      return "Firefox";
    } else if (ua.includes("MSIE") || ua.includes("Trident")) {
      return "Internet Explorer";
    } else {
      return "Unknown Browser";
    }
  }

  public gridDensity: 'compact' | 'cosy' = this.getDeviceType() === 'Desktop' ? 'compact' : 'cosy';


  getOS(): string {
    const ua = navigator.userAgent;
    if (ua.indexOf("Win") !== -1) return "Windows";
    if (ua.indexOf("Mac") !== -1) return "MacOS";
    if (ua.indexOf("X11") !== -1) return "UNIX";
    if (ua.indexOf("Linux") !== -1) return "Linux";
    return "Unknown OS";
  }

  isWindowsDesktop(): boolean {
    return this.getDeviceType() === 'Desktop' && this.getOS() === 'Windows';
    // return this.getDeviceType() === 'Desktop' && this.getOS() === 'MacOS';
  }
}
